import { Box } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import migrationService from "services/migrationService";

import { Button } from "../style";
import { InfoContainer, InfoText } from "./style";

export const ConfirmView = data => {
  const { addPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(false);
  const {
    onBack,
    onNext,
    selection,
    boxesQuantity,
    productBase,
    setNewBatch,
    quantity,
    refresh
  } = data;

  const { batch } = selection;

  const handleConfirm = async () => {
    try {
      setIsLoading(true);

      const response = await migrationService.migration({
        selectionId: selection.id,
        batchId: batch?.id,
        quantity: Number(quantity),
        boxQuantity: Number(boxesQuantity),
        toProductBaseId: productBase.id
      });
      addPopup({ type: "success", title: "Lote criado com sucesso" });

      setNewBatch(response.data);
      refresh(false);
      onNext();
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao fazer migração",
        description: err?.data?.msg
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <InfoContainer style={{ width: "100%" }}>
        <InfoText style={{ marginBottom: "1.25rem" }}>
          Migrando para o produto base: <br />
          <strong>{productBase?.name}</strong>
        </InfoText>
        <InfoText>Quantidade a ser migrada</InfoText>
        <InfoText>
          <strong>
            {quantity}{" "}
            {selection.batch?.product?.purchase_type === 1 ? "Kg" : "Un"}
          </strong>
        </InfoText>
        <InfoText style={{ marginTop: "1.25rem" }}>
          Quantidade de caixa a ser migrada <br />
          <strong>{boxesQuantity}</strong>
        </InfoText>
      </InfoContainer>
      <Box
        display="flex"
        alignItems="flex-end"
        marginTop="1rem"
        flex="1"
        style={{ gap: "1rem" }}
      >
        <Button
          variant="text"
          flex="1"
          startIcon={<NavigateBeforeIcon fontSize="large" />}
          onClick={onBack}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          flex="1"
          isLoading={isLoading}
          onClick={handleConfirm}
        >
          Confirmar
        </Button>
      </Box>
    </>
  );
};
