import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography
} from "@material-ui/core";
import { Button } from "components";
import { usePopup } from "hooks/usePopup";
import { useEffect, useState } from "react";
import userService from "services/userService";
import Modal from "shared/Modal";
import { permissionsAplication } from "utils/permissionAplication";

import { useStyles } from "./styles";

const ModalEdit = ({ open, handleOpenModal, separator }) => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const { id, groups, username } = separator;
  const [listPermission, setListPermission] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);

  const addPermission = permission => {
    userPermissions.some(item => item === permission)
      ? setUserPermissions(state => state.filter(item => item !== permission))
      : setUserPermissions(state => [...state, permission]);
  };

  const handleSetPermission = async () => {
    try {
      const beforePermissions = groups
        .filter(
          group =>
            !Object.entries(permissionsAplication).some(
              ([key]) => key === group.name
            )
        )
        .map(group => group.id);
      await userService.updateUserInfo({
        groupIds: [...beforePermissions, ...userPermissions],
        id
      });

      addPopup({
        type: "success",
        title: "Permissões alteradas com sucesso"
      });

      handleOpenModal();
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao alterar permissões"
      });
    }
  };

  const handleGetPermissions = async () => {
    try {
      const response = await userService.getPermissions();

      const groupPermissions = response?.data;
      const removedPermissions = ["Estoque"];

      setListPermission(
        groupPermissions
          .filter(
            permission =>
              !removedPermissions.includes(permission.name) &&
              Object.entries(permissionsAplication).some(
                ([value]) => value === permission.name
              )
          )
          .map(permission => ({
            ...permission,
            value: permissionsAplication[permission.name]
          }))
      );
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao receber permissões"
      });
    }
  };

  useEffect(() => {
    if (id && open) {
      handleGetPermissions();
    }
  }, [open]);

  useEffect(() => {
    setUserPermissions(groups?.map(group => group.id) || []);
  }, [groups]);

  return (
    <Modal
      title="Permissões"
      open={open}
      handleClose={handleOpenModal}
      style={{ width: "24.5rem" }}
    >
      <Typography className={classes.userName}>{username}</Typography>

      <FormGroup className={classes.formGroup}>
        {listPermission.map(permission => (
          <FormControlLabel
            key={permission?.id}
            checked={userPermissions.some(item => item === permission?.id)}
            control={
              <Checkbox
                value={permission?.id}
                onChange={e => addPermission(parseInt(e.target.value))}
              />
            }
            label={
              <Typography className={classes.title}>
                {permission?.value}
              </Typography>
            }
            className={classes.formControlLabel}
          />
        ))}
      </FormGroup>

      <Box className={classes.buttonBox}>
        <Button variant="contained" onClick={handleSetPermission}>
          <Typography className={classes.button}>Gerar permissões</Typography>
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalEdit;
