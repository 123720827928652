import { Typography, Box, TextField } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import { Button } from "../style";

export const QuantityView = ({
  selection,
  onBack,
  onNext,
  quantity,
  setQuantity
}) => {
  const { batch, available_quantity } = selection;
  const isWeight = batch?.product?.purchase_type === 1;

  const handleChangeBatch = e => {
    const { value: inputValue } = e.target;
    const value = isWeight
      ? parseFloat(inputValue, 10)
      : parseInt(inputValue, 10);

    const isValid = !isNaN(value) && value >= 0 && value <= available_quantity;
    setQuantity(!isValid ? "" : value);
  };

  return (
    <>
      <Box>
        <Typography style={{ color: "#757575" }}>
          Digite a quantidade que será migrada
        </Typography>
      </Box>

      <TextField
        variant="outlined"
        label="Quantidade"
        value={quantity}
        onChange={handleChangeBatch}
        type="number"
        style={{ margin: "1rem 0" }}
        helperText={`Quantidade disponível ${available_quantity - quantity} ${
          isWeight ? "Kg" : "Un"
        }`}
      />

      <Box
        display="flex"
        alignItems="flex-end"
        marginTop="1rem"
        flex="1"
        style={{ gap: "1rem" }}
      >
        <Button
          variant="text"
          flex="1"
          startIcon={<NavigateBeforeIcon fontSize="large" />}
          onClick={onBack}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          flex="1"
          onClick={onNext}
          disabled={!quantity}
        >
          Avançar
        </Button>
      </Box>
    </>
  );
};
