import { Box } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import batchServiceV4 from "services/batchServiceV4";

import { ProductInfo, ProductText, StyledDialog, Image } from "../../style";
import { SelectionPage } from "./SelectionPage";

export const SelectionPopup = ({
  openSelectionPopup,
  handleCloseSelectionPopup,
  detailBatchData
}) => {
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);

  const handleSubmitSelection = async formData => {
    try {
      setLoading(true);
      await batchServiceV4.postSelection(detailBatchData.id, {
        goodQuantity: formData.goodQuantity || 0,
        diverseQuantity: formData.diverseQuantity || 0,
        wasteQuantity: formData.wasteQuantity || 0,
        wrongWeightQuantity: formData.wrongWeightQuantity || 0
      });
      addPopup({
        type: "success",
        title: "Seleção realizada com sucesso"
      });
      handleCloseSelectionPopup();
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao realizar seleção",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog
      open={openSelectionPopup}
      handleClose={handleCloseSelectionPopup}
      title="Seleção"
    >
      <ProductInfo>
        <Box>
          <ProductText>
            Lote: <strong>{detailBatchData.id}</strong>
          </ProductText>
          <ProductText>
            Produto: <strong>{detailBatchData.productName}</strong>
          </ProductText>
          <ProductText>
            Seller: <span>{detailBatchData.seller?.name}</span>
          </ProductText>
          <ProductText>
            Total: <span>{detailBatchData.formattedAvailableQuantity}</span>
          </ProductText>
          <ProductText>
            <span>
              {detailBatchData?.hasDeadline &&
                `${
                  detailBatchData.deadlineExpired
                    ? "Prazo de devolução vencido"
                    : `Devolução até ${detailBatchData.dateDeadline}`
                }`}
            </span>
          </ProductText>
        </Box>
        <Image src={detailBatchData.product?.image} />
      </ProductInfo>
      <SelectionPage
        batchData={detailBatchData}
        handleSubmit={handleSubmitSelection}
        close={handleCloseSelectionPopup}
        isLoading={loading}
      />
    </StyledDialog>
  );
};
