import { Box } from "@material-ui/core";
import { Button, ContainerPage, TitlePage } from "components";
import { useTableParams } from "hooks/useTableParams";
import { useMemo, useRef } from "react";
import batchServiceV4 from "services/batchServiceV4";
import { KeyboardDatePicker, Table } from "shared";
import { formatDate, formatDateTime } from "utils";

import { Classification } from "./Classification";
import { Migration } from "./Migration";

const formatData = data =>
  data.map(item => {
    const { date, time } = formatDateTime(item.created);

    return {
      ...item,
      created: `${date} ${time}`
    };
  });

export const BatchSelectionConfirmation = () => {
  const migrationRef = useRef(null);
  const classificationRef = useRef(null);
  const { data, params, handleSearch, onChange, status, refresh, count } =
    useTableParams({
      service: params => batchServiceV4.get(params),
      paramsDefault: {
        type: ["diverso", "ruim", "peso divergente"],
        available: true
      }
    });

  const columns = [
    {
      field: "id",
      label: "ID",
      align: "left",
      sorted: false,
      disabledSort: true
    },
    {
      field: "created",
      label: "Data de seleção",
      align: "left",
      sorted: false,
      disabledSort: true
    },
    {
      field: "seller",
      label: "Nome do produto",
      align: "left",
      sorted: false,
      disabledSort: true,
      renderCell: ({ data }) => data?.batch?.product?.name
    },
    {
      field: "product",
      label: "Nome do fornecedor",
      align: "left",
      sorted: false,
      disabledSort: true,
      renderCell: ({ data }) => data?.batch?.seller?.name
    },
    {
      field: "batch",
      label: "Lote",
      align: "left",
      style: { minWidth: "120px" },
      disabledSort: true,
      renderCell: ({ data }) => data?.batch?.id
    },
    {
      field: "type",
      label: "Tipo selecionado",
      align: "left",
      style: { minWidth: "120px" },
      disabledSort: true
    },
    {
      field: "quantity",
      label: "Qtd",
      align: "left",
      style: { minWidth: "40x" },
      disabledSort: true
    },
    {
      field: "available_quantity",
      label: "Qtd disponível",
      align: "left",
      style: { minWidth: "40x" },
      disabledSort: true
    },
    {
      field: "migrated_quantity",
      label: "Qtd migrada",
      align: "left",
      style: { minWidth: "40px" },
      disabledSort: true
    },
    {
      field: "classified_quantity",
      label: "Qtd classificada",
      align: "left",
      style: { minWidth: "40px" },
      disabledSort: true
    },
    {
      field: "button1",
      label: "",
      align: "center",
      style: { minWidth: "140px" },
      disabledSort: true,
      renderCell: ({ data }) => {
        return (
          ["diverso", "peso divergente", "ruim"].includes(data.type) && (
            <Button
              style={{ background: "#ef8100" }}
              variant="contained"
              onClick={() => migrationRef.current.handleOpen(data)}
            >
              Migrar
            </Button>
          )
        );
      }
    },
    {
      field: "button",
      label: "",
      align: "center",
      style: { minWidth: "140px" },
      disabledSort: true,
      renderCell: ({ data }) => {
        return (
          <Button
            variant="contained"
            onClick={() => classificationRef.current.handleOpen(data)}
          >
            Classificar
          </Button>
        );
      }
    }
  ];

  const results = useMemo(() => formatData(data), [data]);

  return (
    <>
      <ContainerPage id="back-to-top-anchor">
        <Box>
          <TitlePage>Confirmação de seleção</TitlePage>
        </Box>

        <Table
          columns={columns}
          data={results}
          params={params}
          handleSearch={handleSearch}
          placeholder="Pesquise por ID, lote ou nome do produto"
          onChange={onChange}
          status={status}
          refresh={refresh}
          count={count}
          filters={
            <Box>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                format="dd/MM/yyyy"
                inputVariant="outlined"
                value={
                  params.receiptDate
                    ? new Date(`${params.receiptDate}T00:00:00`)
                    : null
                }
                onChange={date =>
                  onChange({ receiptDate: formatDate(date), page: 1 })
                }
                views={["date"]}
              />
            </Box>
          }
        />
      </ContainerPage>
      <Migration refresh={refresh} ref={migrationRef} />
      <Classification refresh={refresh} ref={classificationRef} />
    </>
  );
};
