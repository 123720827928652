import { MS_API_BACKEND_URL, ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class UserService {
  constructor() {
    this.url = MS_API_BACKEND_URL;
  }

  async getPermissions() {
    const url = `${this.url}/api/auth-internal/v2/group`;
    return await api.get(url);
  }

  async getUser() {
    const url = `${this.url}/api/auth-internal/v2/user/me`;
    return await api.get(url);
  }

  async getUsers(params) {
    const url = `${this.url}/api/auth-internal/v2/user`;
    return await api.get(url, { params });
  }

  async createUser(data) {
    const url = `${this.url}/api/auth-internal/v2/user`;
    return await api.post(url, data);
  }

  async updateUserInfo(data) {
    const url = `${this.url}/api/auth-internal/v2/user/${data.id}`;
    return await api.patch(url, data);
  }

  async resetBlock(id) {
    const url = `${ESTOQUE_BACKEND_URL}/api/auth-internal/v2/user/${id}/block`;
    return await api.patch(url);
  }
}

const userService = new UserService();
export default userService;
