import {
  Accordion,
  AccordionSummary,
  Box,
  Tooltip,
  StepLabel,
  Stepper,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { mapType } from "utils";

import { ReceiptBox } from "./stepsBody/Box";
import { Classification } from "./stepsBody/Classification";
import { QualityBlitz } from "./stepsBody/Quality";
import { ReceiptQuantity } from "./stepsBody/Quantity";
import { Tag } from "./stepsBody/Tag";
import { WeightBlitz } from "./stepsBody/Weight";
import {
  ColorlibConnector,
  ProductInfo,
  ProductText,
  Step,
  AlertDevolution,
  AlertText
} from "./style";

export default function ReceiptSteps({
  batchData,
  blitzData,
  handleClose,
  setTitle
}) {
  const [activeStep, setActiveStep] = useState(batchData?.blitz_step ?? 0);
  const [confirmedQty, setConfirmedQty] = useState(
    batchData.box_quantity || null
  );
  const [price, setPrice] = useState(batchData.combined_price || null);
  const [sampleQualityQty, setSampleQualityQty] = useState(
    batchData.sample_quantity || null
  );
  const [sampleLowQualityQty, setSampleLowQualityQty] = useState(
    batchData.sample_low_quantity || null
  );

  const [receiptQty, setReceiptQty] = useState(null);
  const [boxQty, setBoxQty] = useState(null);

  const [updatedBatch, setUpdatedBatch] = useState({});
  const [isApproved, setIsApproved] = useState(true);

  const { purchase_type } = batchData.product;
  const isWeight = purchase_type === 1;
  const steps = getSteps();

  function getSteps() {
    if (!blitzData) {
      return ["Entrada", "Inserir quantidade", "Imprimir etiqueta"];
    }
    return [
      "Entrada",
      "Blitz de qualidade",
      "Blitz do peso",
      "Inserir classificações",
      "Inserir quantidade",
      "Imprimir etiqueta"
    ];
  }

  const handleChangeSampleQualityQty = e => {
    const { value: inputValue } = e.target;
    const value = isWeight
      ? parseFloat(inputValue, 10)
      : parseInt(inputValue, 10);

    const isValid = !isNaN(value) && value >= 0;
    setSampleQualityQty(!isValid ? "" : value);
  };

  const handleChangeSampleLowQualityQty = e => {
    const { value: inputValue } = e.target;
    const value = isWeight
      ? parseFloat(inputValue, 10)
      : parseInt(inputValue, 10);

    const isValid = !isNaN(value) && value >= 0;
    setSampleLowQualityQty(!isValid ? "" : value);
  };

  const handleChangeReceiptQty = e => {
    const { value: inputValue } = e.target;
    const value = isWeight
      ? parseFloat(inputValue, 10)
      : parseInt(inputValue, 10);
    const isValid = !isNaN(value) && value >= 0;
    setReceiptQty(!isValid ? "" : value);
  };

  const handleChangeBoxQty = e => {
    const { value: inputValue } = e.target;
    const value = parseInt(inputValue, 10);
    const isValid = !isNaN(value) && value >= 0;
    setBoxQty(!isValid ? "" : value);
  };

  function getStepContent(step) {
    const stepBodyWithRule = {
      0: (
        <ReceiptBox
          handleNext={handleNext}
          batchData={batchData}
          blitzData={blitzData}
          confirmedQty={confirmedQty}
          setConfirmedQty={setConfirmedQty}
          handleCloseOpenSteps={handleClose}
          price={price}
          setPrice={setPrice}
        />
      ),
      1: (
        <QualityBlitz
          confirmedQty={confirmedQty}
          batchData={batchData}
          blitzData={blitzData}
          handleNext={handleNext}
          handleChangeSampleQualityQty={handleChangeSampleQualityQty}
          handleChangeSampleLowQualityQty={handleChangeSampleLowQualityQty}
          sampleQualityQty={sampleQualityQty}
          sampleLowQualityQty={sampleLowQualityQty}
          handleCloseOpenSteps={handleClose}
          isApproved={isApproved}
          setIsApproved={setIsApproved}
        />
      ),
      2: (
        <WeightBlitz
          blitzData={blitzData}
          batchData={batchData}
          handleNext={handleNext}
          handleCloseOpenSteps={handleClose}
        />
      ),
      3: (
        <Classification
          batchData={batchData}
          handleNext={handleNext}
          sampleQualityQty={sampleQualityQty}
          sampleLowQualityQty={sampleLowQualityQty}
          handleClose={handleClose}
        />
      ),
      4: (
        <ReceiptQuantity
          batchData={batchData}
          handleNext={handleNext}
          receiptQty={receiptQty}
          handleChangeReceiptQty={handleChangeReceiptQty}
          setUpdatedBatch={setUpdatedBatch}
          hasBlitz={!!blitzData}
          handleChangeBoxQty={handleChangeBoxQty}
          boxQty={boxQty}
        />
      ),
      5: <Tag handleCloseOpenSteps={handleClose} updatedBatch={updatedBatch} />
    };

    const stepBodyWithoutRule = {
      0: (
        <ReceiptBox
          handleNext={handleNext}
          batchData={batchData}
          confirmedQty={confirmedQty}
          setConfirmedQty={setConfirmedQty}
          price={price}
          setPrice={setPrice}
          handleCloseOpenSteps={handleClose}
        />
      ),
      1: (
        <ReceiptQuantity
          batchData={batchData}
          handleNext={handleNext}
          receiptQty={receiptQty}
          handleChangeReceiptQty={handleChangeReceiptQty}
          setUpdatedBatch={setUpdatedBatch}
          hasBlitz={!!blitzData}
          handleChangeBoxQty={handleChangeBoxQty}
          boxQty={boxQty}
        />
      ),
      2: <Tag handleCloseOpenSteps={handleClose} updatedBatch={updatedBatch} />
    };
    return blitzData ? stepBodyWithRule[step] : stepBodyWithoutRule[step];
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  useEffect(() => {
    const title = steps[activeStep];
    setTitle(title);
  }, [activeStep]);

  return (
    <Box style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ width: "100%", paddingTop: 0 }}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => {
          const hideSteps = !isApproved && index >= 2;
          return (
            <Step key={label} style={{ visibility: hideSteps && "hidden" }}>
              <Tooltip title={label} arrow placement="top">
                <StepLabel />
              </Tooltip>
            </Step>
          );
        })}
      </Stepper>

      {batchData.seller?.has_seller_open_devolution && (
        <Box>
          <AlertDevolution>
            <AlertText className="bold">⚠️ Atenção:</AlertText>
            <AlertText>Este seller possui uma devolução em aberto</AlertText>
          </AlertDevolution>
        </Box>
      )}

      <Accordion style={{ marginBottom: "1rem" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ background: "#F5F8F3" }}
        >
          <Typography>
            Produto: <strong>{batchData.product?.name}</strong>
          </Typography>
        </AccordionSummary>

        <ProductInfo>
          <ProductText>
            Seller: <span>{batchData.seller?.name}</span>
          </ProductText>
          <ProductText>
            Porção: <span>{mapType(batchData.product?.purchase_type)}</span>
          </ProductText>
        </ProductInfo>
      </Accordion>
      {getStepContent(activeStep)}
    </Box>
  );
}
