import * as Yup from "yup";

export const validationSchema = batchData => {
  const { purchaseType, unClassifiedQty } = batchData;

  return Yup.object().shape({
    goodQuantity: Yup.number()
      .nullable()
      .notRequired()
      .min(0, "Deve ser um número positivo")
      .typeError("Deve ser um número")
      .test(
        "integerValidation",
        "Deve ser um número inteiro",
        function (value) {
          if (value === null || value === undefined) return true;
          return !(purchaseType === 2 && !Number.isInteger(value));
        }
      )
      .test(
        "maxValidation",
        `O valor máximo total é ${unClassifiedQty}`,
        function (value) {
          const {
            diverseQuantity = 0,
            wasteQuantity = 0,
            wrongWeightQuantity = 0
          } = this.parent;
          if (value === null || value === undefined) return true;
          const maxAllowed =
            unClassifiedQty -
            (diverseQuantity + wasteQuantity + wrongWeightQuantity);
          return value <= maxAllowed;
        }
      ),
    diverseQuantity: Yup.number()
      .nullable()
      .notRequired()
      .min(0, "Deve ser um número positivo")
      .typeError("Deve ser um número")
      .test(
        "integerValidation",
        "Deve ser um número inteiro",
        function (value) {
          if (value === null || value === undefined) return true;
          return !(purchaseType === 2 && !Number.isInteger(value));
        }
      )
      .test(
        "maxValidation",
        `O valor máximo total é ${unClassifiedQty}`,
        function (value) {
          const {
            goodQuantity = 0,
            wasteQuantity = 0,
            wrongWeightQuantity = 0
          } = this.parent;
          if (value === null || value === undefined) return true;
          const maxAllowed =
            unClassifiedQty -
            (goodQuantity + wasteQuantity + wrongWeightQuantity);
          return value <= maxAllowed;
        }
      ),
    wasteQuantity: Yup.number()
      .nullable()
      .notRequired()
      .min(0, "Deve ser um número positivo")
      .typeError("Deve ser um número")
      .test(
        "integerValidation",
        "Deve ser um número inteiro",
        function (value) {
          if (value === null || value === undefined) return true;
          return !(purchaseType === 2 && !Number.isInteger(value));
        }
      )
      .test(
        "maxValidation",
        `O valor máximo total é ${unClassifiedQty}`,
        function (value) {
          const {
            goodQuantity = 0,
            diverseQuantity = 0,
            wrongWeightQuantity = 0
          } = this.parent;
          if (value === null || value === undefined) return true;
          const maxAllowed =
            unClassifiedQty -
            (goodQuantity + diverseQuantity + wrongWeightQuantity);
          return value <= maxAllowed;
        }
      ),
    wrongWeightQuantity: Yup.number()
      .nullable()
      .notRequired()
      .min(0, "Deve ser um número positivo")
      .typeError("Deve ser um número")
      .test(
        "integerValidation",
        "Deve ser um número inteiro",
        function (value) {
          if (value === null || value === undefined) return true;
          return !(purchaseType === 2 && !Number.isInteger(value));
        }
      )
      .test(
        "maxValidation",
        `O valor máximo total é ${unClassifiedQty}`,
        function (value) {
          const {
            goodQuantity = 0,
            diverseQuantity = 0,
            wasteQuantity = 0
          } = this.parent;
          if (value === null || value === undefined) return true;
          const maxAllowed =
            unClassifiedQty - (goodQuantity + diverseQuantity + wasteQuantity);
          return value <= maxAllowed;
        }
      )
  });
};
