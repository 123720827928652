import { Box, CircularProgress, Typography } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { forwardRef, useImperativeHandle, useState } from "react";
import batchServiceV3 from "services/batchServiceV3";
import { mapType } from "utils";

import { Form } from "./Form";
import {
  LoadingContent,
  ProductInfo,
  ProductText,
  StyledDialog,
  Image
} from "./styles";

const Component = ({ refresh }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selection, setSelection] = useState({});
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setIsOpen(false);
    setSelection({});
  };

  const handleOpen = data => {
    setSelection(data);
    setIsOpen(true);
  };

  const handleSubmit = async data => {
    try {
      setLoading(true);

      await batchServiceV3.postBatchMove(selection.batch?.id, data);
      addPopup({
        type: "success",
        title: "Classificação realizada com sucesso"
      });
      refresh();
      onClose();
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao realizar classificação",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({ handleOpen }));

  return (
    <StyledDialog open={isOpen} handleClose={onClose} title="Classificar">
      <ProductInfo>
        <Box>
          <ProductText>
            Lote: <strong>{selection.batch?.id}</strong>
          </ProductText>
          <ProductText>
            Produto: <strong>{selection.batch?.product?.name}</strong>
          </ProductText>
          <ProductText>
            Seller: <span>{selection.batch?.seller?.name}</span>
          </ProductText>
          <ProductText>
            Porção:{" "}
            <span>{mapType(selection.batch?.product?.purchase_type)}</span>
          </ProductText>
          <ProductText>
            Quantidade disponível: <span>{selection.available_quantity}</span>
          </ProductText>
        </Box>
        <Image src={selection.batch?.product?.image} />
      </ProductInfo>
      {loading ? (
        <LoadingContent>
          <Typography>
            Registrando as informações, por favor aguarde...
          </Typography>
          <CircularProgress color="primary" />
        </LoadingContent>
      ) : (
        <Form selection={selection} handleSubmit={handleSubmit} />
      )}
    </StyledDialog>
  );
};

export const Classification = forwardRef(Component);
