import { Box, alpha, makeStyles, styled } from "@material-ui/core";
import MuiLinearProgress from "@material-ui/core/LinearProgress";
import { Search } from "shared/Search";

export const Content = styled(Box)({
  display: "flex",
  padding: "1rem 0rem",
  overflowX: "auto",
  height: "70vh"
});

export const useStyles = makeStyles({
  myCard: {
    width: "300px",
    minWidth: "300px",
    height: "665px",
    margin: "0.5rem",
    background: "#EDEDED",
    borderRadius: "0rem 0rem 0.75rem 0.75rem",
    overflow: "hidden"
  },
  title: {
    fontFamily: "Roboto",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "capitalize"
  },
  mainBox: {
    position: "relative",
    width: "100%",
    height: "auto",
    background: "#FFF",
    flexShrink: 0,
    padding: "0.5rem"
  },
  line: {
    borderTop: "4px solid",
    width: "100%"
  },
  boxHeader: {
    zIndex: 10,
    position: "sticky",
    top: 0,
    background: "#FFF",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "51px",
    marginBottom: "10px",
    borderRadius: "0rem 0rem 0.5rem 0.5rem",
    boxShadow: "0px 4px 4.3px 0px rgba(0, 0, 0, 0.10)",
    padding: "0 0.5rem"
  },
  titleBatch: {
    color: "#757575",
    fontFamily: "Roboto",
    fontSize: "0.8rem",
    fontWeight: 400
  },
  span: {
    color: "#353535",
    fontFamily: "Roboto",
    fontSize: "1rem",
    fontWeight: 500
  },
  productName: {
    color: "#353535",
    fontFamily: "Roboto",
    fontSize: "0.815rem",
    fontWeight: 500,
    marginTop: "8px"
  },
  migrateName: {
    color: "#757575",
    fontFamily: "Roboto",
    fontSize: "0.75rem",
    fontWeight: 400
  },
  dateMigrate: {
    color: "#D84315",
    fontFamily: "Roboto",
    fontSize: "0.75rem",
    fontWeight: 500
  },
  dateText: {
    color: "#757575",
    fontFamily: "Roboto",
    fontSize: "0.75rem",
    fontWeight: 400
  },
  length: {
    color: "#A3A3A3",
    fontFamily: "Roboto",
    fontSize: "0.875rem",
    fontWeight: 400
  },
  scrollContainer: {
    overflowY: "auto",
    maxHeight: "100%",
    "&::-webkit-scrollbar": {
      width: "0.2em"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent"
    }
  },
  warning: {
    display: "flex",
    justifyContent: "center"
  },
  button: {
    width: "5%",
    height: "5%",
    marginRight: "1rem",
    background: "transparent",

    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  arrow: {
    color: "#000",
    display: "flex",
    marginLeft: "2rem"
  }
});

export const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center"
}));

export const SearchContent = styled(Box)(({ theme }) => ({
  display: "flex"
}));

export const SearchInput = styled(Search)(({ theme }) => ({
  width: "100%",
  maxWidth: "21.5rem",
  height: "3.5rem",
  marginLeft: "2.5rem",
  [theme.breakpoints.between(0, 700)]: {
    marginLeft: 0
  }
}));

export const LinearProgress = styled(MuiLinearProgress)(
  ({ background = "#89BD23" }) => ({
    bottom: "0",
    left: "0",
    width: "100%",
    backgroundColor: alpha(background, 0.3),
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: background
    }
  })
);
