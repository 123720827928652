import { mapPurchaseType } from "utils";
const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return {
    date,
    time
  };
};

const formatDeadlineForAnalysis = batch => {
  const receiptDate = new Date(batch?.batch.receipt_date);
  receiptDate.setDate(
    receiptDate.getDate() + (batch.deadline_for_analysis ?? 0)
  );
  const formatted = formatDateTime(receiptDate);
  return `${formatted.date} ${formatted.time}`;
};

const getDateDiffInMinutes = (startDate, endDate) => {
  const diffInMileSeconds = Math.abs(new Date(startDate) - new Date(endDate));
  const diffInMinutes = Math.floor(diffInMileSeconds / (1000 * 60));
  return diffInMinutes;
};

export const formatData = data => {
  return (data || []).reduce((acc, batch) => {
    const typeOfMeasure = mapPurchaseType(batch.product?.purchase_type);
    const batchQuantity = batch.remaining_qty;
    const formattedWeight = [batchQuantity || 0].toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3
    });

    const isReset = !!getDateDiffInMinutes(batch.created, batch.updated);

    const fomattedQuantity =
      typeOfMeasure === "Kg" ? `${formattedWeight} Kg` : `${batchQuantity} Un`;
    const formatted = {
      id: batch.id,
      batchId: batch.batch?.id,
      createdDate: formatDateTime(batch?.created).date,
      createdTime: formatDateTime(batch?.created).time,
      updatedDate: formatDateTime(batch?.updated).date,
      updatedTime: formatDateTime(batch?.updated).time,
      receiptDate: formatDateTime(batch?.batch.receipt_date).date,
      receiptTime: formatDateTime(batch?.batch.receipt_date).time,
      seller: batch.seller?.name,
      productName: batch.product?.name,
      fomattedQuantity,
      quantity: batchQuantity,
      reason: batch.reason?.name,
      status: batch.status?.name,
      stageId: batch.stage?.id,
      stage: batch.stage?.name,
      statusId: batch.status?.id,
      combinedReturnedQuantity: batch?.combined_return_qty,
      createdBy: batch.created_by?.username,
      updatedBy: batch.updated_by?.username,
      instructions: batch.refusal_instructions,
      typeOfMeasure: typeOfMeasure,
      devolutionMethod: batch.devolution_method,
      remainingQty: batch?.remaining_qty,
      deadlineForAnalysis:
        !!batch.deadline_for_analysis && formatDeadlineForAnalysis(batch),
      isReset
    };
    return [...acc, formatted];
  }, []);
};
