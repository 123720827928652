import { Box, Paper, TableBody, Tooltip, styled } from "@material-ui/core";
import RepeatIcon from "@material-ui/icons/Repeat";
import { CustomLineProgress } from "components/LineProgress";
import { Link } from "react-router-dom";

import {
  BodyCell as BodyCellMui,
  BodyRow,
  ConfirmButton,
  HeadCell as HeadCellMui,
  StyledTable,
  StyledTableHead,
  TableContent
} from "../../style";

const HeadCell = styled(HeadCellMui)({ padding: "1rem" });
const BodyCell = styled(BodyCellMui)({ padding: "1rem" });

export const BatchTable = ({
  batchData,
  loading,
  handleOpenConfirm,
  handleOpenEditModal
}) => {
  return (
    <Box>
      {!!loading && <CustomLineProgress />}
      <TableContent component={Paper}>
        <StyledTable stickyHeader>
          <StyledTableHead>
            <HeadCell>Lote</HeadCell>
            <HeadCell>Recebido em</HeadCell>
            <HeadCell>Selecionado em</HeadCell>
            <HeadCell>Produto</HeadCell>
            <HeadCell>Seller</HeadCell>
            <HeadCell>Qnt</HeadCell>
            <HeadCell>Motivo</HeadCell>
            <HeadCell>Devolução combinada</HeadCell>
            <HeadCell>Prazo devolução</HeadCell>
            <HeadCell></HeadCell>
            <HeadCell></HeadCell>
            <HeadCell></HeadCell>
          </StyledTableHead>
          <TableBody>
            {batchData?.map(batch => (
              <BodyRow key={batch.id}>
                <BodyCell>
                  <Link
                    to={`lotes/${batch.batchId}`}
                    style={{ color: "#89BD23", fontWeight: 700 }}
                  >
                    {batch.batchId}
                  </Link>
                </BodyCell>
                <BodyCell>
                  {batch.receiptDate} {batch.receiptTime}
                </BodyCell>
                <BodyCell>
                  {batch.createdDate} {batch.createdTime}
                </BodyCell>
                <BodyCell>{batch.productName}</BodyCell>
                <BodyCell>{batch.seller}</BodyCell>
                <BodyCell>{batch.fomattedQuantity}</BodyCell>
                <BodyCell>{batch.reason}</BodyCell>
                <BodyCell>{batch.instructions}</BodyCell>
                <BodyCell>{batch.deadlineForAnalysis}</BodyCell>
                <BodyCell>
                  {batch.isReset && (
                    <Tooltip
                      title={`Resetado por: ${batch.updatedBy} em ${batch.updatedDate} às ${batch.updatedTime}`}
                      placement="top"
                      arrow
                    >
                      <RepeatIcon color="error" />
                    </Tooltip>
                  )}
                </BodyCell>

                <BodyCell>
                  {batch.statusId === 7 &&
                    ["3", "4"].includes(batch.instructions?.[0]) && (
                      <ConfirmButton
                        disabled={batch.statusId !== 7}
                        onClick={() => handleOpenEditModal(batch)}
                        style={{ background: "#EF8100" }}
                      >
                        Editar
                      </ConfirmButton>
                    )}
                </BodyCell>
                <BodyCell>
                  {batch.statusId === 7 && (
                    <ConfirmButton
                      disabled={batch.statusId !== 7}
                      onClick={() => handleOpenConfirm(batch.id)}
                    >
                      Confirmar
                    </ConfirmButton>
                  )}
                </BodyCell>
              </BodyRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContent>
    </Box>
  );
};
