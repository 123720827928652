import { usePopup } from "hooks/usePopup";
import { useMemo, useRef } from "react";
import batchService from "services/batchService";
import { refusalInstructions } from "utils/refusalInstructions";

import { BatchInfoContent, BatchInfoText, StyledDialog } from "../../style";
import { Form } from "./Form";

const formatInitialValues = data => ({
  id: data.id,
  refusalInstructions: data.instructions?.[0]
});

export const Edit = ({ isOpen, setIsOpen, refresh, data }) => {
  const formRef = useRef(null);

  const { addPopup } = usePopup();

  const initialValues = useMemo(() => formatInitialValues(data), [data]);

  const handleClose = () => {
    setIsOpen({ isOpen: false, data: {} });
    formRef.current?.resetForm();
  };

  const onSubmit = async dataForm => {
    try {
      await batchService.patchBatchReturn({
        id: dataForm.id,
        data: {
          refusal_instructions:
            refusalInstructions[dataForm.refusalInstructions]
        }
      });
      addPopup({ type: "success", title: "Registro alterado com sucesso" });
      refresh();
      handleClose();
    } catch (err) {
      if (err.response.status !== 401) {
        addPopup({
          type: "error",
          title: "Erro ao alterar registro",
          description: err.response?.data?.msg ?? ""
        });
      }
    }
  };

  return (
    <StyledDialog
      open={isOpen}
      handleClose={handleClose}
      title="Editar registro"
    >
      <BatchInfoContent>
        <BatchInfoText>
          Lote: <strong>{data?.batchId}</strong>
        </BatchInfoText>
        <BatchInfoText>
          Produto: <span>{data?.productName}</span>
        </BatchInfoText>
        <BatchInfoText>
          Seller: <span>{data?.seller}</span>
        </BatchInfoText>
        {!!data?.instructions && (
          <BatchInfoText>
            Instruções: <span>{data?.instructions}</span>
          </BatchInfoText>
        )}
      </BatchInfoContent>
      <Form ref={formRef} initialValues={initialValues} onSubmit={onSubmit} />
    </StyledDialog>
  );
};
