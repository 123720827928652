import {
  Box,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { ContainerPage, Paginate, PermissionGate, TitlePage } from "components";
import { CardOperator } from "components/pages/Users/CardOperator";
import { LoadingPage } from "components/pages/Users/LoadingPage";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import { useEffect, useRef, useState } from "react";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { BsFilter } from "react-icons/bs";
import { MdOutlineSearchOff } from "react-icons/md";
import { VscAdd } from "react-icons/vsc";
import userService from "services/userService";
import { MenuPopover } from "shared";
import { Search } from "shared/Search";
import { permissionsAplication } from "utils";

import { ConfirmResetBlock } from "./ConfirmReset";
import { ModalCreateUser } from "./ModalCreateUser";
import { ModalEditUserPassword } from "./ModalEditUserPassword";
import ModalEdit from "./ModalEditUserPermissions";
import { useStyles } from "./styles";

export const Users = () => {
  const classes = useStyles();
  const { addPopup } = usePopup();
  const refConfirmResetBlock = useRef(null);
  const userRef = useRef(null);
  const [modalForgetPassword, setModalForgetPassword] = useState(false);
  const [listPermission, setListPermission] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [FilterOpen, setFilterOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [separator, setSeparator] = useState({});
  const { data, params, status, count, onChange, handleSearch } =
    useTableParams({
      service: params => userService.getUsers(params),
      paramsDefault: { pageSize: 30 }
    });
  const isPending = status === "pending";

  const handleChangeStatus = data => {
    setSeparator(data);
    handleOpenModal();
  };

  const handleOpenModal = () => {
    setModalOpen(state => !state);
  };

  const handleChangePassword = data => {
    setSeparator(data);
    handleOpenModalEditUserPermissions();
  };

  const handleOpenModalEditUserPermissions = () => {
    setModalForgetPassword(state => !state);
  };

  const handleClick = () => {
    setFilterOpen(state => !state);
  };

  const handleGetPermissions = async () => {
    try {
      const { data } = await userService.getPermissions();
      return setListPermission(
        data
          .filter(permission =>
            Object.entries(permissionsAplication).some(
              ([value]) => value === permission.name
            )
          )
          .map(permission => ({
            ...permission,
            value: permissionsAplication[permission.name]
          }))
      );
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao receber permissões"
      });
    }
  };

  useEffect(() => {
    handleGetPermissions();
  }, []);

  return (
    <>
      <ContainerPage style={{ paddingBottom: "1rem" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
        >
          <TitlePage>Usuários</TitlePage>

          <Box className={classes.boxAlign}>
            <PermissionGate permissions={["Tech", "Previsão"]}>
              <Typography
                className={classes.filter}
                ref={userRef}
                onClick={handleClick}
              >
                Filtros
                <BsFilter size="1.5rem" />
              </Typography>

              <MenuPopover
                open={FilterOpen}
                onClose={handleClick}
                anchorEl={userRef.current}
                arrow
              >
                <Box className={classes.boxPermissions}>
                  <FormGroup>
                    <RadioGroup
                      onChange={e => onChange({ groupId: e.target.value })}
                      value={String(params.groupId)}
                      defaultValue=""
                    >
                      <FormControlLabel
                        value=""
                        control={<Radio />}
                        label="Todos"
                      />

                      {listPermission.map(permission => (
                        <FormControlLabel
                          key={permission.id}
                          value={String(permission?.id)}
                          control={<Radio />}
                          label={permission?.value}
                        />
                      ))}
                    </RadioGroup>
                  </FormGroup>
                </Box>
              </MenuPopover>

              <Search
                placeholder="Quem você busca?"
                onChange={handleSearch}
                className={classes.search}
                autoFocus
              />
            </PermissionGate>
          </Box>
        </Box>

        <Grid container spacing={2}>
          {isPending && <LoadingPage />}

          {!isPending && data.length === 0 && (
            <Typography className={classes.noOperator}>
              <MdOutlineSearchOff size={50} />
              Nenhum usuário encontrado
            </Typography>
          )}

          {data.map((separator, index) => (
            <Grid
              key={index}
              container
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              justifyContent="center"
            >
              <CardOperator
                handleOpenModal={() => handleChangeStatus(separator)}
                handleUpdatePassword={() => handleChangePassword(separator)}
                handleResetBlock={() =>
                  refConfirmResetBlock?.current.handleOpen(separator)
                }
                {...separator}
              />
            </Grid>
          ))}
        </Grid>
        <Box margin="1.5rem 0 0" display="flex" justifyContent="center">
          <Paginate
            breakLabel="..."
            nextLabel={<AiOutlineRight />}
            forcePage={params.page - 1}
            onPageChange={({ selected }) => onChange({ page: selected + 1 })}
            pageRangeDisplayed={3}
            pageCount={Math.ceil(count / params.pageSize)}
            previousLabel={<AiOutlineLeft />}
            renderOnZeroPageCount={null}
            loading={status === "pending"}
          />
        </Box>
      </ContainerPage>

      <ModalCreateUser
        open={openModal}
        handleClose={() => {
          setOpenModal(state => !state);
        }}
      />

      <ModalEdit
        open={isModalOpen}
        handleOpenModal={handleOpenModal}
        separator={separator}
      />

      <ModalEditUserPassword
        open={modalForgetPassword}
        handleOpenModal={handleOpenModalEditUserPermissions}
        separator={separator}
      />
      <ConfirmResetBlock ref={refConfirmResetBlock} />

      <Fab
        className={classes.addFloatButton}
        onClick={() => setOpenModal(true)}
      >
        <IconButton className={classes.addButton}>
          <VscAdd size="2.2rem" className={classes.iconButton} />
        </IconButton>
      </Fab>
    </>
  );
};
