import { Box, CircularProgress, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  ActionContent,
  BatchInfoContent,
  BatchInfoSubText,
  BatchInfoText,
  BoxInput,
  ButtonsContent,
  LoadingContent,
  StyledButton,
  StyledDialog
} from "../../style";

const getValidationSchema = (remainingQty, typeOfMeasure) =>
  yup.object({
    physicalReturnQty: yup
      .number()
      .positive("Valor deve ser positivo")
      .required("Campo obrigatório")
      .test(
        "is-devoluted-quantity-valid",
        "A quantidade devolvida não pode ser maior que a disponível",
        function (physicalReturnQty) {
          return physicalReturnQty <= remainingQty;
        }
      )
      .test(
        "integerValidation",
        "Deve ser um número inteiro",
        function (value) {
          return !(typeOfMeasure === "Un" && !Number.isInteger(value));
        }
      )
  });

export const ConfirmPopup = ({
  openConfirm,
  handleCloseConfirm,
  handleConfirm,
  data,
  loading
}) => {
  const onSubmit = dataForm => {
    const isSuccess = handleConfirm({
      batch: data.batchId,
      batchReturn: data.id,
      physicalReturnedQuantity: dataForm.physicalReturnQty
    });
    isSuccess && formik.resetForm({});
  };

  const formik = useFormik({
    initialValues: {
      physicalReturnQty: ""
    },
    validationSchema: getValidationSchema(
      data?.remainingQty,
      data?.typeOfMeasure
    ),
    onSubmit,
    validateOnChange: false,
    context: { combinedReturnedQuantity: data?.combinedReturnedQuantity }
  });

  const handleClose = () => {
    handleCloseConfirm();
    formik.resetForm({});
  };

  return (
    <StyledDialog
      open={openConfirm}
      handleClose={handleClose}
      title={"Devolução física"}
    >
      <BatchInfoContent>
        <BatchInfoText>
          Lote: <strong>{data?.batchId}</strong>
        </BatchInfoText>
        <BatchInfoText>
          Produto: <span>{data?.productName}</span>
        </BatchInfoText>
        <BatchInfoText>
          Quantidade: <span>{data?.fomattedQuantity}</span>
        </BatchInfoText>
        <BatchInfoText>
          Seller: <span>{data?.seller}</span>
        </BatchInfoText>
        {!!data?.devolutionMethod && (
          <BatchInfoText>
            Método de devolução: <span>{data?.devolutionMethod}</span>
          </BatchInfoText>
        )}
      </BatchInfoContent>
      <ActionContent>
        {loading ? (
          <>
            <BatchInfoSubText>Por favor aguarde...</BatchInfoSubText>
            <LoadingContent>
              <CircularProgress color="primary" />
            </LoadingContent>
          </>
        ) : (
          <>
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              noValidate
              display="flex"
              flexDirection="column"
              style={{ gap: "1.5rem" }}
            >
              <BoxInput>
                <Box flex="1">
                  <TextField
                    fullWidth
                    type="number"
                    name="physicalReturnQty"
                    variant="outlined"
                    label="Quantidade devolvida"
                    value={formik.values.physicalReturnQty}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      (formik.touched.physicalReturnQty ||
                        formik.submitCount > 0) &&
                      !!formik.errors.physicalReturnQty
                    }
                    helperText={
                      (formik.touched.physicalReturnQty ||
                        formik.submitCount > 0) &&
                      formik.errors.physicalReturnQty
                    }
                  />
                </Box>
              </BoxInput>

              <ButtonsContent>
                <StyledButton
                  variant="contained"
                  fullWidth
                  name="cancelButton"
                  onClick={handleClose}
                  style={{ textTransform: "none" }}
                >
                  Cancelar
                </StyledButton>

                <StyledButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  name="confirmButton"
                  style={{ textTransform: "none" }}
                >
                  Confirmar
                </StyledButton>
              </ButtonsContent>
            </Box>
          </>
        )}
      </ActionContent>
    </StyledDialog>
  );
};
