import { ESTOQUE_BACKEND_URL } from "../settings";
import { api } from "./api";

class BatchServiceV4 {
  constructor() {
    this.url = ESTOQUE_BACKEND_URL;
  }

  async postSelection(batchId, data) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v4/${batchId}/selection`;
    return await api.post(url, data);
  }

  async get(params) {
    const url = `${this.url}/api/blueberry/v1/warehouse/batch/v4/selection`;
    return await api.get(url, { params });
  }
}

const batchServiceV4 = new BatchServiceV4();
export default batchServiceV4;
