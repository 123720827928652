import {
  Box,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { TitlePage } from "components";
import { ButtonLink } from "components/ButtonLink";
import { Loading } from "components/Loading";
import { addDays, startOfDay, isSameDay, isAfter } from "date-fns";
import { useAuth } from "hooks/useAuth";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import { useEffect, useMemo, useRef, useState } from "react";
import kanbanService from "services/kanbanService";
import selectionGroupsService from "services/selectionGroupsService";
import userService from "services/userService";
import { stringToColor } from "utils";

import { FormFilterStyle } from "../SelectionGroups/styles";
import { CardComponent } from "./components";
import { SelectionPopup } from "./SelectionPopup";
import {
  Content,
  Header,
  LinearProgress,
  SearchContent,
  SearchInput,
  useStyles
} from "./styles";

const formatDateTime = dateTime => {
  const dateToFormat = dateTime && new Date(dateTime);
  const date = dateToFormat?.toLocaleDateString();
  const time = dateToFormat?.toLocaleTimeString();
  return { date, time };
};

const formatDateDeadline = (date, combinedDate) => {
  const newDate = startOfDay(new Date(date));
  const deadlineFormat = addDays(newDate, combinedDate);
  const today = startOfDay(new Date());
  const isToday = isSameDay(today, deadlineFormat);
  const passedTheDeadline = isAfter(today, deadlineFormat);

  return {
    deadlineFormat: deadlineFormat.toLocaleDateString(),
    deadlineFormatHour: formatDateTime(date).time,
    isToday,
    passedTheDeadline
  };
};

const formattedBatch = batch => {
  const data = {
    ...batch,
    receiptDateFormatted:
      batch.receiptDate && formatDateTime(batch.receiptDate).date,
    created: formatDateTime(batch.created).date
  };

  const combinedDate = data.deadlineForAnalysis;

  const formattedDate = formatDateDeadline(
    data.receiptDate || data.created,
    combinedDate
  );

  return { ...data, ...formattedDate };
};

const groupBatchData = (data, selectionGroups) => {
  const groupsObject = data.reduce(
    (acc, group) => ({ ...acc, [group.id]: group }),
    {}
  );

  const groups = selectionGroups.map(selectionGroup => {
    const group = groupsObject[selectionGroup.id];

    if (!group) {
      return { ...selectionGroup, batchs: [], count: 0 };
    }

    const batchs = group.batchs.reduce((batchs, batch) => {
      const formatBatch = formattedBatch(batch);
      if (batchs[formatBatch.sellerId]) {
        batchs[formatBatch.sellerId] = [
          ...batchs[formatBatch.sellerId],
          formatBatch
        ];
        return batchs;
      }
      batchs[formatBatch.sellerId] = [formatBatch];
      return batchs;
    }, {});

    const count = Object.entries(batchs).reduce(
      (acc, [, batchs]) => acc + batchs.length,
      0
    );
    return { ...selectionGroup, batchs, count };
  });

  const noSelectionGroup = groups.find(({ name }) =>
    name.includes("Sem grupo")
  );

  if (!noSelectionGroup) {
    return groups;
  }

  return [
    noSelectionGroup,
    ...groups.filter(({ name }) => !name.includes("Sem grupo"))
  ];
};

export const KanbanSelectionGroup = () => {
  let firstRender = true;
  const timeout = useRef();
  const { userId } = useAuth();
  const { addPopup } = usePopup();
  const controller = new AbortController();
  const signal = controller.signal;
  const classes = useStyles();
  const [selectionGroups, setSelectionGroups] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [dataAllGroups, setDataAllGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [selectionPopup, setSelectionPopup] = useState({
    isOpen: false,
    data: {}
  });
  const { data, setData, handleSearch, refresh, errorMessage } = useTableParams(
    {
      service: params => kanbanService.getSelectionGroup(params, signal),
      paramsDefault: {
        status: 1,
        pageSize: 150,
        page: 1
      }
    }
  );

  const handleUserData = async (search = undefined) => {
    try {
      const { data } = await userService.getUsers({
        page: 1,
        pageSize: 1000,
        search
      });

      setDataUser(data.results);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Desculpe, ocorreu um erro",
        description: errorMessage ?? "Erro ao buscar usuários"
      });
    }
  };

  const pollingData = async () => {
    try {
      if (!firstRender) {
        await refresh();
      }
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao buscar dados"
      });
    } finally {
      firstRender = false;
      if (!controller.signal.aborted) {
        // 5 minutos
        const mm = 5 * 60 * 1000;
        timeout.current = setTimeout(() => pollingData(true), mm);
      }
    }
  };

  const getAllSectionGroups = async () => {
    try {
      const responseAllGroups = await selectionGroupsService.getGroups({
        page: 1,
        pageSize: 1000
      });

      setDataAllGroups(responseAllGroups.results);
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao buscar grupos de seleção"
      });
    }
  };

  const getSelectionGroups = async () => {
    try {
      setLoading(true);
      const response = await selectionGroupsService.getGroups({
        page: 1,
        pageSize: 1000,
        responsibleId: selectAll
          ? undefined
          : selectedItems.map(item => item.id).join(",")
      });

      setSelectionGroups([
        ...response.results,
        { id: 0, name: "Sem grupo de seleção" }
      ]);
      await pollingData();
      setLoading(false);
    } catch (err) {
      addPopup({
        type: "error",
        title: "Erro ao buscar grupos de seleção"
      });
      setLoading(false);
    }
  };

  const handleFilter = event => {
    const {
      target: { value }
    } = event;

    if (value.includes("Todos")) {
      setSelectAll(true);
      setSelectedItems([]);
    } else {
      setSelectedItems(
        value
          .map(username => dataUser.find(item => item?.username === username))
          .filter(item => item !== undefined)
      );
      setSelectAll(false);
    }
  };

  const groups = useMemo(
    () => groupBatchData(data, selectionGroups),
    [data, selectionGroups]
  );

  const handleOpenSelectionPopup = batch => {
    setSelectionPopup({ isOpen: true, data: batch });
  };

  useEffect(() => {
    handleUserData();
    getAllSectionGroups();
    setFirstLoading(false);
  }, []);

  useEffect(() => {
    setSelectAll(selectedItems.length === 0 && userId && true);
  }, [selectedItems]);

  useEffect(() => {
    if (selectedItems.length > 0 || selectAll) {
      getSelectionGroups();
    }

    return () => {
      controller.abort();
      clearTimeout(timeout.current);
    };
  }, [userId, selectedItems, selectAll]);

  useEffect(() => {
    !!errorMessage &&
      addPopup({
        type: "error",
        title: errorMessage || "Erro ao buscar dados de seleção"
      });
  }, [errorMessage]);

  useEffect(() => {
    if (!firstLoading && dataUser.length > 0) {
      setSelectAll(true);
      setSelectedItems([]);
    }
  }, [dataUser, firstLoading]);

  const updateUnClassifiedQty = (batchId, unClassifiedQty) => {
    const newData = data.map(selectionGroup => {
      return {
        ...selectionGroup,
        batchs: selectionGroup.batchs
          .map(batch => {
            if (batch.batchId === batchId) {
              return { ...batch, unClassifiedQty };
            }
            return batch;
          })
          .filter(batch => !!batch.unClassifiedQty)
      };
    });
    setData(newData);
  };

  return (
    <>
      <Box>
        <Header>
          <ButtonLink className={classes.button} to="/app/selecao">
            <ArrowBack className={classes.arrow} />
          </ButtonLink>
          <TitlePage>Quadro de seleção</TitlePage>
        </Header>
        <SearchContent style={{ gap: "1rem" }}>
          <SearchInput placeholder="Id ou produto" onChange={handleSearch} />
          <FormFilterStyle variant="outlined" fullWidth>
            <InputLabel shrink variant="outlined">
              Filtrar por usuário
            </InputLabel>
            <Select
              label="Filtrar por usuário"
              value={
                selectAll
                  ? ["Todos"]
                  : selectedItems?.map(item => item?.username)
              }
              onChange={handleFilter}
              multiple
              input={<OutlinedInput label="Tag" />}
              renderValue={selected => selected?.join(", ")}
            >
              <MenuItem key="Todos" value="Todos">
                <Checkbox
                  checked={selectAll}
                  onClick={() => setSelectAll(true)}
                />
                <ListItemText primary="Todos" />
              </MenuItem>
              {dataUser
                .filter(item =>
                  dataAllGroups?.some(group => group.responsibleId === item.id)
                )
                .sort((a, b) => a.username.localeCompare(b.username))
                .map(item => (
                  <MenuItem key={item.id} value={item.username}>
                    <Checkbox
                      checked={selectedItems?.some(
                        user => user?.id === item?.id
                      )}
                    />
                    <ListItemText primary={item.username} />
                  </MenuItem>
                ))}
            </Select>
          </FormFilterStyle>
        </SearchContent>
        {loading && <LinearProgress background="#89BD23" />}

        <Content>
          {groups.map(group => (
            <CardComponent
              key={group.id}
              batchData={group.batchs}
              title={group.name}
              color={stringToColor(group.name)}
              count={group.count}
              handleSelect={handleOpenSelectionPopup}
            />
          ))}
          {!groups.length && <Loading text="Buscando dados" />}
        </Content>
      </Box>
      <SelectionPopup
        openSelectionPopup={selectionPopup.isOpen}
        detailBatchData={selectionPopup.data}
        updateUnClassifiedQty={updateUnClassifiedQty}
        handleCloseSelectionPopup={() =>
          setSelectionPopup({ isOpen: false, data: {} })
        }
      />
    </>
  );
};
