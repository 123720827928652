import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { usePopup } from "../../hooks/usePopup";
import storageService from "../../services/storageService";
import { useDebaunce } from "../../utils/debaunce";

const AutoCompleteProductBase = props => {
  const {
    errors,
    name,
    control,
    defaultValue,
    className,
    onChange,
    disabled,
    onlyPNC
  } = props;
  const { addPopup } = usePopup();
  const [loadingSearchBaseProducts, setLoadingSearchBaseProducts] =
    useState(false);
  const [options, setOptions] = useState([]);

  const handleProductBaseData = async (search = undefined) => {
    try {
      setLoadingSearchBaseProducts(true);
      const response = await storageService.getBaseProducts({
        page: 1,
        pageSize: 800,
        search,
        status: "active"
      });

      onlyPNC?.state
        ? setOptions(
            response.data.results.filter(
              product =>
                product.name.toLowerCase().includes("pnc") &&
                product.name
                  .toLowerCase()
                  .includes(onlyPNC.productName.toLowerCase())
            )
          )
        : setOptions(response.data.results);
    } catch (err) {
      if (err?.response?.status) {
        addPopup({
          type: "error",
          title: "Desculpe, ocorreu um erro",
          description: "Erro ao pesquisar produto base."
        });
      }
    } finally {
      setLoadingSearchBaseProducts(false);
    }
  };

  const debaunce = useDebaunce({ fn: handleProductBaseData, delay: 500 });

  const handleProductBase = e => {
    debaunce(e.target.value);
  };

  useEffect(() => {
    if (disabled) return;

    handleProductBaseData();
  }, []);

  if (control) {
    return (
      <Controller
        defaultValue={defaultValue || null}
        disabled={disabled}
        render={({ field }) => (
          <Autocomplete
            loading={loadingSearchBaseProducts}
            options={options}
            getOptionLabel={option => option.name}
            onChange={(_, data) => {
              field.onChange(data);
              onChange && onChange(data);
            }}
            value={field.value}
            className={className}
            noOptionsText="Produto não encontrado"
            loadingText="Procurando produto..."
            disabled={disabled}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Produto Base"
                error={!!errors?.[name]}
                helperText={errors?.[name]?.message}
                disabled={disabled}
                onChange={handleProductBase}
                InputProps={{
                  ...params.InputProps,
                  className: className,
                  clearButton: false,
                  endAdornment: (
                    <>
                      {loadingSearchBaseProducts && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        )}
        name={name}
        control={control}
      />
    );
  }

  return (
    <Autocomplete
      loading={loadingSearchBaseProducts}
      options={options}
      getOptionLabel={option => option.name}
      onChange={onChange}
      className={className}
      noOptionsText="Produto não encontrado"
      loadingText="Procurando produto..."
      defaultValue={defaultValue || null}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Produto Base"
          onChange={handleProductBase}
          disabled={disabled}
          InputProps={{
            ...params.InputProps,
            className: className,
            clearButton: false,
            endAdornment: (
              <>
                {loadingSearchBaseProducts && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default AutoCompleteProductBase;
