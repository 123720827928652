import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import { Button } from "components";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle } from "react";
import { refusalInstructions } from "utils/refusalInstructions";

import { validationSchemaEdit } from "./validationSchema";

const Component = ({ initialValues, onSubmit }, ref) => {
  const formik = useFormik({
    initialValues: initialValues || { toDate: null },
    validationSchema: validationSchemaEdit,
    onSubmit: onSubmit,
    validateOnChange: false
  });

  useImperativeHandle(ref, () => ({ resetForm: formik.resetForm }));

  const handleChangeRefusalInstructions = e => {
    const { value } = e.target;
    formik.setFieldValue("refusalInstructions", value);
    !!Number(value) < 3 && formik.setFieldValue("deadlineForAnalysis", "");
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      style={{ marginTop: "2rem" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <FormControl
            fullWidth
            variant="outlined"
            error={
              (formik.touched.refusalInstructions || formik.submitCount > 0) &&
              !!formik.errors.refusalInstructions
            }
          >
            <InputLabel id="refusalInstructions-label">
              Devolução combinada
            </InputLabel>
            <Select
              labelId="refusalInstructions-label"
              id="refusalInstructions"
              name="refusalInstructions"
              value={formik.values.refusalInstructions}
              onChange={handleChangeRefusalInstructions}
              label="Instruções de recusa"
            >
              {Object.entries(refusalInstructions)
                .filter(([key]) => key !== "1")
                .map(([key, value]) => (
                  <MenuItem
                    key={key}
                    value={key}
                    style={{ textTransform: "none" }}
                  >
                    {value}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {(formik.touched.refusalInstructions || formik.submitCount > 0) &&
                formik.errors.refusalInstructions}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item container justifyContent="flex-end">
          <Button
            isLoading={formik.isSubmitting}
            variant="contained"
            type="submit"
            style={{ width: "10rem" }}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export const Form = forwardRef(Component);
