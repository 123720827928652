import { Box } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { TitlePage } from "components";
import { ButtonLink } from "components/ButtonLink";
import { useRef } from "react";
import kanbanService from "services/kanbanService";
import { useDebaunce } from "utils";

import { Kanban } from "./components/Kanban";
import {
  Content,
  Header,
  SearchContent,
  SearchInput,
  useStyles
} from "./styles";

export const CardKanban = () => {
  const receiptRef = useRef(null);
  const blitzRef = useRef(null);
  const unloadingRef = useRef(null);
  const selectionRef = useRef(null);
  const devolutionRef = useRef(null);
  const devolutionPhysicalRef = useRef(null);
  const devolutionNfRef = useRef(null);
  const devolutionRepositionRef = useRef(null);
  const classes = useStyles();

  const debaunceBatchSearch = useDebaunce({
    fn: handleBatchSearch,
    delay: 800
  });

  function handleBatchSearch(e) {
    receiptRef.current?.handleSearch(e);
    blitzRef.current?.handleSearch(e);
    unloadingRef.current?.handleSearch(e);
    selectionRef.current?.handleSearch(e);
    devolutionRef.current?.handleSearch(e);
    devolutionPhysicalRef.current?.handleSearch(e);
    devolutionNfRef.current?.handleSearch(e);
    devolutionRepositionRef.current?.handleSearch(e);
  }

  return (
    <Box>
      <Header>
        <ButtonLink className={classes.button} to="/app/dashboard">
          <ArrowBack className={classes.arrow} />
        </ButtonLink>
        <TitlePage>Quadro do estoque</TitlePage>
      </Header>
      <SearchContent>
        <SearchInput
          placeholder="Id ou produto"
          onChange={debaunceBatchSearch}
        />
      </SearchContent>
      <Content>
        <Kanban
          service={(...rest) => kanbanService.getReceipt(...rest)}
          paramsDefault={{ pageSize: 100, page: 1 }}
          title="Entrada (Financeiro)"
          color="#628EFF"
          ref={receiptRef}
        />
        <Kanban
          service={(...rest) => kanbanService.getBlitz(...rest)}
          paramsDefault={{ pageSize: 100, page: 1 }}
          title="Blitz (Controle)"
          color="#FF6262"
          ref={blitzRef}
        />
        <Kanban
          service={(...rest) => kanbanService.getUnloading(...rest)}
          paramsDefault={{ pageSize: 100, page: 1, status: 1 }}
          title="Descarregamento (Armazém)"
          color="#628EFF"
          ref={unloadingRef}
        />
        <Kanban
          service={(...rest) => kanbanService.getSelection(...rest)}
          paramsDefault={{
            pageSize: 100,
            page: 1,
            status: 1,
            onlyDevolution: true
          }}
          title="Seleção (Armazém)"
          color="#4AA800"
          ref={selectionRef}
        />
        <Kanban
          service={(...rest) => kanbanService.getDevolution(...rest)}
          paramsDefault={{ pageSize: 100, page: 1 }}
          title="Alinhamento com o seller (Compras)"
          color="#00bfa5"
          ref={devolutionRef}
        />
        <Kanban
          service={(...rest) => kanbanService.getDevolutionPhysical(...rest)}
          paramsDefault={{ pageSize: 100, page: 1 }}
          title="Devolução Física"
          color="#B39DDB"
          ref={devolutionPhysicalRef}
        />
        <Kanban
          service={(...rest) => kanbanService.getDevolutionNF(...rest)}
          paramsDefault={{ pageSize: 100, page: 1 }}
          title="Abatimento NF/Reembolso (Financeiro)"
          color="#b5c206"
          ref={devolutionNfRef}
        />
        <Kanban
          service={(...rest) => kanbanService.getDevolutionReposition(...rest)}
          paramsDefault={{ pageSize: 100, page: 1 }}
          title="Reposição (Financeiro)"
          color="#0d00bf"
          ref={devolutionRepositionRef}
        />
      </Content>
    </Box>
  );
};
