import { Box, MenuItem, TextField } from "@material-ui/core";
import { Button } from "components";
import { useFormik } from "formik";

import { ButtonsContent } from "./styles";
import { validationSchema } from "./validationSchema";

export const storageReasonOptions = [
  { id: 17, name: "Alinhamento com o seller" },
  { id: 19, name: "Waste Devolução para o seller" },
  { id: 3, name: "Waste operacional" }
];

const batchReasons = [
  { id: 1, name: "Qualidade ruim" },
  { id: 2, name: "Peso médio" }
];

export const Form = ({ handleSubmit, selection }) => {
  const validation = validationSchema(selection);

  const formik = useFormik({
    initialValues: { selectionId: selection.id },
    validationSchema: validation,
    onSubmit: handleSubmit,
    validateOnChange: false
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
    >
      <TextField
        fullWidth
        name="quantity"
        type="number"
        variant="outlined"
        label="Quantidade"
        value={formik.values.quantity}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          (formik.touched.quantity || formik.submitCount > 0) &&
          !!formik.errors.quantity
        }
        helperText={
          (formik.touched.quantity || formik.submitCount > 0) &&
          formik.errors.quantity
        }
      />

      <TextField
        fullWidth
        select
        name="storageReasonId"
        label="Tipo de movimentação"
        variant="outlined"
        value={formik.values.storageReasonId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          (formik.touched.storageReasonId || formik.submitCount > 0) &&
          !!formik.errors.storageReasonId
        }
        helperText={
          (formik.touched.storageReasonId || formik.submitCount > 0) &&
          formik.errors.storageReasonId
        }
      >
        {storageReasonOptions.map(option => (
          <MenuItem
            key={option.id}
            value={option.id}
            style={{ textTransform: "none" }}
          >
            {option.name}
          </MenuItem>
        ))}
      </TextField>

      {selection.type === "diverso" && (
        <TextField
          fullWidth
          select
          name="batchReasonId"
          label="Motivo"
          variant="outlined"
          value={formik.values.batchReasonId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            (formik.touched.batchReasonId || formik.submitCount > 0) &&
            !!formik.errors.batchReasonId
          }
          helperText={
            (formik.touched.batchReasonId || formik.submitCount > 0) &&
            formik.errors.batchReasonId
          }
        >
          {batchReasons.map(option => (
            <MenuItem
              key={option.id}
              value={option.id}
              style={{ textTransform: "none" }}
            >
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      )}

      <ButtonsContent>
        <Button type="submit" variant="contained" fullWidth>
          Classificar
        </Button>
      </ButtonsContent>
    </Box>
  );
};
