import { Box } from "@material-ui/core";
import { usePopup } from "hooks/usePopup";
import { useState } from "react";
import batchServiceV4 from "services/batchServiceV4";

import { SelectionPage } from "./SelectionPage";
import { ProductInfo, ProductText, StyledDialog, Image } from "./styles";

export const SelectionPopup = ({
  openSelectionPopup,
  handleCloseSelectionPopup,
  detailBatchData,
  updateUnClassifiedQty
}) => {
  const { addPopup } = usePopup();
  const [loading, setLoading] = useState(false);

  const handleSubmitSelection = async formData => {
    try {
      setLoading(true);
      await batchServiceV4.postSelection(detailBatchData.batchId, {
        goodQuantity: formData.goodQuantity || 0,
        diverseQuantity: formData.diverseQuantity || 0,
        wasteQuantity: formData.wasteQuantity || 0,
        wrongWeightQuantity: formData.wrongWeightQuantity || 0
      });
      addPopup({
        type: "success",
        title: "Seleção realizada com sucesso"
      });

      const unClassifiedQty = [
        formData.goodQuantity || 0,
        formData.diverseQuantity || 0,
        formData.wasteQuantity || 0,
        formData.wrongWeightQuantity || 0
      ].reduce((acc, value) => acc - value, detailBatchData.unClassifiedQty);

      updateUnClassifiedQty(detailBatchData.batchId, unClassifiedQty);
      handleCloseSelectionPopup();
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro ao realizar seleção",
        description: errorMessage
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog
      open={openSelectionPopup}
      handleClose={handleCloseSelectionPopup}
      title="Seleção"
    >
      <ProductInfo>
        <Box>
          <ProductText>
            Lote: <strong>{detailBatchData.batchId}</strong>
          </ProductText>
          <ProductText>
            Produto: <strong>{detailBatchData.productName}</strong>
          </ProductText>
          <ProductText>
            Seller: <span>{detailBatchData.sellerName}</span>
          </ProductText>
          <ProductText>
            Total: <span>{detailBatchData.unClassifiedQty}</span>
          </ProductText>
        </Box>
        <Image src={detailBatchData.product?.image} />
      </ProductInfo>
      <SelectionPage
        batchData={detailBatchData}
        handleSubmit={handleSubmitSelection}
        close={handleCloseSelectionPopup}
        isLoading={loading}
      />
    </StyledDialog>
  );
};
