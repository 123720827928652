import { Box } from "@material-ui/core";
import { ContainerPage, TitlePage } from "components";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import batchService from "services/batchService";

import { AlignmentDateFilter } from "./components/AlignmentDateFilter";
import { StatusFilter } from "./components/Filter";
import { FinishPopup } from "./components/FinishPopup";
import { Paginator } from "./components/Paginator";
import { BatchTable } from "./components/table";
import { UpdateDeadlinePopup } from "./components/UpdateDeadlinePopup";
import {
  Content,
  PopupContent,
  SearchContent,
  SearchInput,
  StyledAlertDialog,
  TextInfoAlert
} from "./style";
import { formatData } from "./utils/formatData";

export const BatchReturn = () => {
  const { addPopup } = usePopup();
  const urlParams = new URLSearchParams(location.search);
  const batchReturnId = urlParams.get("batchReturnId");
  const urlParamsStatusId = urlParams.get("statusId");
  const urlParamsStageId = urlParams.get("stageId");
  const [batchDetail, setBatchDetail] = useState({});
  const [updateLoading, setUpdateLoading] = useState(false);
  const [openFinish, setOpenFinish] = useState(false);
  const [openUpdateDeadline, setOpenUpdateDeadline] = useState(false);
  const [openAlertBatchNotFound, setOpenAlertBatchNotFound] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [alignmentDateFrom, setAlignmentDateFrom] = useState(() => {
    const dateStart = new Date();
    dateStart.setHours(0, 0, 0);
    return dateStart;
  });
  const [alignmentDateTo, setAlignmentDateTo] = useState(() => {
    const dateEnd = new Date();
    dateEnd.setHours(23, 59, 59);
    return dateEnd;
  });
  const { data, params, handleSearch, status, refresh, onChange, count } =
    useTableParams({
      service: params => batchService.getBatchesReturn(params),
      paramsDefault: {
        page: 1,
        search: batchReturnId,
        statusId: urlParamsStatusId || 3,
        stageId: urlParamsStageId || 12,
        alignmentDateFrom: batchReturnId && undefined,
        alignmentDateTo: batchReturnId && undefined
      }
    });

  const handleConfirm = async (status, stage) => {
    try {
      setUpdateLoading(true);
      const { id } = batchDetail;
      const data = {
        status,
        stage
      };
      await batchService.patchBatchReturn({
        id,
        data
      });
      addPopup({ type: "success", title: "Análise confirmada" });
      onChange({ page: 1 });
      refresh();
      setOpenFinish(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro confirmar análise",
        description: errorMessage
      });
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleReset = async () => {
    try {
      setUpdateLoading(true);
      const { id } = batchDetail;
      await batchService.resetBatchReturn(id);
      addPopup({ type: "success", title: "Análise resetada com sucesso" });
      onChange({ page: 1 });
      refresh();
      setOpenFinish(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro resetar análise",
        description: errorMessage
      });
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleUpdateDeadlineConfirm = async date => {
    try {
      setUpdateLoading(true);
      const { id } = batchDetail;
      const data = {
        combined_return_deadline: date
      };
      await batchService.patchBatchReturn({
        id,
        data
      });
      addPopup({ type: "success", title: "Prazo alterado com sucesso!" });
      onChange({ page: 1 });
      refresh();
      handleCloseDeadlinePopup();
    } catch (error) {
      const errorMessage = error?.response?.data?.msg;
      addPopup({
        type: "error",
        title: "Erro alterar data",
        description: errorMessage
      });
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleFilter = (status, stage) => {
    const isAlignedDate =
      !params.statusId ||
      (params.statusId === 3 && [12, 13].includes(params.stageId));
    onChange({
      statusId: status || undefined,
      stageId: stage || undefined,
      alignmentDateFrom: isAlignedDate ? alignmentDateFrom : undefined,
      alignmentDateTo: isAlignedDate ? alignmentDateTo : undefined,
      page: 1
    });
  };

  const handleChangeAlignmentDate = dates => {
    const [start, end] = dates;

    start?.setHours(0, 0, 0);
    end?.setHours(23, 59, 59);

    setAlignmentDateFrom(start);
    setAlignmentDateTo(end);
  };

  const handleOpenFinish = batchId => {
    const result = batchData.find(({ id }) => id === Number(batchId));

    if (batchReturnId && !result) {
      setOpenAlertBatchNotFound(true);
    } else {
      setBatchDetail(result);
      setOpenFinish(true);
    }
  };

  const handleOpenPopupDeadline = () => {
    setOpenUpdateDeadline(true);
  };

  const handleCloseFinish = () => {
    setRedirect(true);
    setOpenFinish(false);
  };

  const handleCloseDeadlinePopup = () => {
    setOpenUpdateDeadline(false);
  };

  const isAlignedDateFilterVisible = () => {
    return (
      !params.statusId ||
      (params.statusId === 3 && [12, 13].includes(params.stageId))
    );
  };

  const batchData = formatData(data);

  const handleCloseAlertBatchNotFound = () => {
    setOpenAlertBatchNotFound(false);
    setRedirect(true);
  };

  useEffect(() => {
    !!alignmentDateFrom &&
      !!alignmentDateTo &&
      !batchReturnId &&
      handleFilter(params.statusId, params.stageId);
  }, [alignmentDateFrom, alignmentDateTo]);

  useEffect(() => {
    const isSuccess = status === "success";
    if (isSuccess && batchReturnId) {
      handleOpenFinish(batchReturnId);
    }
  }, [batchData.length, status]);

  return (
    <ContainerPage id="back-to-top-anchor">
      <Box>
        <TitlePage>Aguardando Reembolso/Reposição (Financeiro)</TitlePage>
      </Box>
      <Content>
        <SearchContent>
          <SearchInput
            placeholder="Seller, lote ou produto"
            onChange={handleSearch}
          />
          <StatusFilter
            statusId={params.statusId}
            stageId={params.stageId}
            handleFilter={handleFilter}
          />
          {isAlignedDateFilterVisible() && (
            <AlignmentDateFilter
              statusId={params.statusId}
              stageId={params.stageId}
              dateFrom={alignmentDateFrom}
              dateTo={alignmentDateTo}
              handleFilterDate={handleChangeAlignmentDate}
            />
          )}
        </SearchContent>
        <BatchTable
          batchData={batchData}
          loading={status === "pending"}
          handleOpenFinish={handleOpenFinish}
          status={params.statusId}
          stage={params.stageId}
        />
        <Paginator
          handleChangePage={({ selected }) => onChange({ page: selected + 1 })}
          loading={status === "pending"}
          page={params.page}
          totalPages={Math.ceil(count / params.pageSize)}
        />
        <FinishPopup
          openFinish={openFinish}
          data={batchDetail}
          handleCloseFinish={handleCloseFinish}
          handleFinish={handleConfirm}
          loading={updateLoading}
          handleReset={handleReset}
          handleOpenPopupDeadline={handleOpenPopupDeadline}
        />
        <UpdateDeadlinePopup
          openPopup={openUpdateDeadline}
          data={batchDetail}
          handleCloseDeadlinePopup={handleCloseDeadlinePopup}
          loading={updateLoading}
          handleUpdateDeadlineConfirm={handleUpdateDeadlineConfirm}
        />
        <StyledAlertDialog
          open={openAlertBatchNotFound}
          title="Aviso"
          handleClose={handleCloseAlertBatchNotFound}
        >
          <PopupContent>
            <TextInfoAlert>Alinhamento não encontrado</TextInfoAlert>
          </PopupContent>
        </StyledAlertDialog>
        {redirect && <Redirect to="/app/dashboard/kanban" />}
      </Content>
    </ContainerPage>
  );
};
