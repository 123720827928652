import {
  Card,
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  Divider
} from "@material-ui/core";
import { CircleActive } from "components";
import { usePopup } from "hooks/usePopup";
import { useTableParams } from "hooks/useTableParams";
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from "react";
import { BsFlagFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AccordionSummary } from "shared";
import { formatDate, splitDate } from "utils";
import { v4 } from "uuid";

import { useStyles, LinearProgress } from "../../styles";
import { formatBatch, groupBatchData } from "../../utils";

export const Component = ({ service, paramsDefault, title, color }, ref) => {
  let firstRender = true;
  const timeout = useRef();
  const { addPopup } = usePopup();
  const { data, count, status, refresh, handleSearch, errorMessage } =
    useTableParams({
      service: params => service(params, signal),
      paramsDefault
    });
  const classes = useStyles();
  const controller = new AbortController();
  const signal = controller.signal;
  const titles = [
    "Alinhamento",
    "Abatimento",
    "Reposição",
    "Seleção",
    "Devolução"
  ];
  const cardTitle = title.split(" ")[0];

  const batchData = useMemo(
    () => groupBatchData(data.map(batch => formatBatch(batch, cardTitle))),
    [data]
  );

  const pollingData = async () => {
    try {
      if (!firstRender) {
        await refresh();
      }
    } catch {
      addPopup({
        type: "error",
        title: "Erro ao buscar dados"
      });
    } finally {
      firstRender = false;
      if (!controller.signal.aborted) {
        // 5 minutos
        const mm = 5 * 60 * 1000;
        timeout.current = setTimeout(() => pollingData(true), mm);
      }
    }
  };

  const showError = msg => {
    addPopup({
      type: "error",
      title,
      description: msg || "Erro ao buscar dados"
    });
  };

  useEffect(() => {
    pollingData();
    return () => {
      controller.abort();
      clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    !!errorMessage && showError(errorMessage);
  }, [errorMessage]);

  useImperativeHandle(ref, () => ({ handleSearch }));

  const hasSellerOpenDevolution = data.some(
    ({ hasSellerOpenDevolution }) => hasSellerOpenDevolution
  );

  const content = {
    true: Object.entries(batchData)
      .sort(([sellerIdA, batchsA], [sellerIdB, batchsB]) => {
        if (titles.includes(title.split(" ")[0])) {
          return (
            splitDate(batchsA[0]?.deadlineFormat) -
            splitDate(batchsB[0]?.deadlineFormat)
          );
        }
        return 0;
      })
      .map(([sellerId, batchs]) => {
        const isToday = batchs.some(({ isToday }) => isToday);
        const passedTheDeadline = batchs.some(
          ({ passedTheDeadline }) => passedTheDeadline
        );
        const qtyProductOutOfStock = batchs.filter(
          ({ outOfStock }) => outOfStock
        ).length;
        return (
          <Accordion key={sellerId} style={{ margin: 0 }}>
            <AccordionSummary>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography>
                  <span style={{ color: "#353535" }}>
                    {batchs[0]?.sellerId}
                  </span>
                  {" - " + batchs[0]?.sellerName}
                </Typography>
                {hasSellerOpenDevolution && (
                  <CircleActive
                    color="#D84315"
                    title="Seller tem uma devolução aberta"
                  />
                )}
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ gap: "0.5rem" }}
                >
                  {!!qtyProductOutOfStock && (
                    <CircleActive
                      color="#D84315"
                      title={`${qtyProductOutOfStock} produto${
                        qtyProductOutOfStock > 1 ? "s" : ""
                      } fora de estoque`}
                    />
                  )}

                  {isToday && passedTheDeadline ? (
                    <>
                      <BsFlagFill color="#D84315" size={12} />
                      <BsFlagFill color="#DAAA00" size={12} />
                    </>
                  ) : passedTheDeadline ? (
                    <BsFlagFill color="#D84315" size={12} />
                  ) : isToday ? (
                    <BsFlagFill color="#DAAA00" size={12} />
                  ) : null}
                  <Typography style={{ color: "#A3A3A3" }}>
                    {batchs.length}
                  </Typography>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0, flexDirection: "column" }}>
              {batchs
                .sort((a, b) => {
                  if (titles.includes(title.split(" ")[0])) {
                    return (
                      splitDate(a.deadlineFormat) - splitDate(b.deadlineFormat)
                    );
                  }
                  return 0;
                })
                .map(batch => {
                  const id = v4();

                  const redirectLink =
                    {
                      Entrada: `/app/recebimento?batchId=${batch.batchId}`,
                      Alinhamento: `/app/alinhamento-com-o-seller?batchReturnId=${batch.batchReturnId}`,
                      Devolução: `/app/devolucao-fisica?batchReturnId=${batch.batchReturnId}`,
                      Blitz: `/app/recebimento?blitzBatchId=${batch.batchId}`,
                      Descarregamento: `/app/recebimento?unloadingBatchId=${batch.batchId}`,
                      Abatimento: `/app/batch-return?batchReturnId=${batch.batchReturnId}&statusId=3&stageId=12`,
                      Reposição: `/app/batch-return?batchReturnId=${batch.batchReturnId}&statusId=3&stageId=13`
                    }[cardTitle] || `/app/lotes/${batch.batchId}`;

                  return (
                    <>
                      {title.includes("Seleção") ? (
                        <Link
                          to={`/app/selecao?batchId=${batch.batchId}`}
                          style={{ textDecoration: "none" }}
                          key={id}
                        >
                          <Box className={classes.mainBox}>
                            <Box
                              position="absolute"
                              right="10px"
                              style={{
                                display: "flex",
                                flexDirection: "column"
                              }}
                            >
                              <span
                                style={{ color: "#EB001B", fontWeight: 500 }}
                              >
                                {batch.deadlineFormat}
                              </span>
                              <span
                                style={{
                                  color: "#EB001B",
                                  fontSize: "0.75rem",
                                  fontWeight: 500
                                }}
                              >
                                {batch.deadlineFormatHour}
                              </span>
                            </Box>
                            <Box>
                              <Typography className={classes.titleBatch}>
                                Lote:{" "}
                                <span className={classes.span}>
                                  {batch.batchId}
                                </span>
                              </Typography>
                            </Box>
                            {batch.isMigrate && (
                              <Typography className={classes.migrateName}>
                                Migrado em:{" "}
                                <span className={classes.dateMigrate}>
                                  {formatDate(batch.created)}
                                </span>
                              </Typography>
                            )}
                            <Typography className={classes.productName}>
                              {batch.productName}
                            </Typography>
                            <Typography className={classes.dateText}>
                              {title.includes("Seleção")
                                ? batch?.combinedReturn
                                : `${
                                    batch.receiptDateFormatted || batch.created
                                  } - SL ${batch.shelfLife}`}
                            </Typography>
                          </Box>
                        </Link>
                      ) : (
                        <Link
                          to={redirectLink}
                          style={{ textDecoration: "none" }}
                          key={id}
                        >
                          <Box
                            className={classes.mainBox}
                            style={{
                              background: batch.passedTheDeadline
                                ? "#FFE4E0"
                                : batch.isToday
                                ? "#FFF4CB"
                                : "#FFF"
                            }}
                          >
                            {titles.includes(title.split(" ")[0]) && (
                              <Box
                                position="absolute"
                                right="10px"
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <span
                                  style={{ color: "#EB001B", fontWeight: 500 }}
                                >{`${batch?.deadlineFormat}`}</span>
                                <span
                                  style={{
                                    color: "#EB001B",
                                    fontSize: "0.75rem",
                                    fontWeight: 500
                                  }}
                                >{`${batch?.deadlineFormatHour}`}</span>
                              </Box>
                            )}
                            <Box
                              display="flex"
                              alignItems="center"
                              style={{ gap: "0.7rem" }}
                            >
                              <Typography className={classes.titleBatch}>
                                Lote:{" "}
                                <span className={classes.span}>
                                  {batch.batchId}
                                </span>
                              </Typography>
                              {batch.outOfStock && (
                                <CircleActive
                                  color="#D84315"
                                  title="Produto fora de estoque"
                                />
                              )}
                            </Box>
                            <Typography className={classes.productName}>
                              {batch.productName}
                            </Typography>
                            <Typography className={classes.dateText}>
                              {batch.receiptDateFormatted || batch.created} - SL{" "}
                              {batch.shelfLife}
                            </Typography>
                          </Box>
                        </Link>
                      )}
                      <Divider />
                    </>
                  );
                })}
            </AccordionDetails>
          </Accordion>
        );
      }),
    false: status !== "pending" && (
      <Typography className={classes.warning}>Sem dados</Typography>
    )
  }[!!count];

  return (
    <Card className={classes.myCard}>
      <Box className={classes.scrollContainer}>
        <Box className={classes.line} color={color}></Box>
        <Box className={classes.boxHeader}>
          <Typography className={classes.title} style={{ color }}>
            {title}
          </Typography>
          <Typography className={classes.length}>{count}</Typography>
          {status === "pending" && <LinearProgress background={color} />}
        </Box>
        {content}
      </Box>
    </Card>
  );
};

export const Kanban = memo(forwardRef(Component));
