import { TextField } from "@material-ui/core";
import { Button } from "components";
import { Formik, Form, ErrorMessage, Field } from "formik";

import { ButtonsContent } from "./styles";
import { validationSchema } from "./validationsSchema";

export const SelectionPage = ({ handleSubmit, batchData, isLoading }) => {
  const validation = validationSchema(batchData);
  const initialValues = {
    goodQuantity: undefined,
    diverseQuantity: undefined,
    wasteQuantity: undefined,
    wrongWeightQuantity: undefined
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
        >
          <Field
            as={TextField}
            fullWidth
            name="goodQuantity"
            type="number"
            variant="outlined"
            label="Bom"
          />
          <ErrorMessage
            name="goodQuantity"
            component="div"
            style={{ color: "red" }}
          />
          <Field
            as={TextField}
            fullWidth
            name="diverseQuantity"
            type="number"
            variant="outlined"
            label="Diverso"
          />
          <ErrorMessage
            name="diverseQuantity"
            component="div"
            style={{ color: "red" }}
          />
          <Field
            as={TextField}
            fullWidth
            name="wasteQuantity"
            type="number"
            variant="outlined"
            label="Ruim (Waste)"
          />
          <ErrorMessage
            name="wasteQuantity"
            component="div"
            style={{ color: "red" }}
          />
          <Field
            as={TextField}
            fullWidth
            name="wrongWeightQuantity"
            type="number"
            variant="outlined"
            label="Peso divergente"
          />
          <ErrorMessage
            name="wrongWeightQuantity"
            component="div"
            style={{ color: "red" }}
          />

          <ButtonsContent>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              isLoading={isLoading}
            >
              Salvar
            </Button>
          </ButtonsContent>
        </Form>
      )}
    </Formik>
  );
};
