import { Box, Paper, TableBody, Tooltip, styled } from "@material-ui/core";
import RepeatIcon from "@material-ui/icons/Repeat";
import { CustomLineProgress } from "components/LineProgress";
import { Link } from "react-router-dom";

import {
  BodyCell as BodyCellMui,
  BodyRow,
  ConfirmButton,
  HeadCell as HeadCellMui,
  StyledTable,
  StyledTableHead,
  TableContent
} from "../../style";

const HeadCell = styled(HeadCellMui)({ padding: "1rem" });
const BodyCell = styled(BodyCellMui)({ padding: "1rem" });

export const BatchTable = ({ batchData, loading, handleOpenConfirm }) => {
  return (
    <Box>
      {!!loading && <CustomLineProgress />}
      <TableContent component={Paper}>
        <StyledTable stickyHeader>
          <StyledTableHead>
            <HeadCell>Lote</HeadCell>
            <HeadCell>Produto</HeadCell>
            <HeadCell>Seller</HeadCell>
            <HeadCell>Quantidade</HeadCell>
            <HeadCell>Motivo</HeadCell>
            <HeadCell>Método de devolução</HeadCell>
            <HeadCell>Prazo devolução</HeadCell>
            <HeadCell></HeadCell>
          </StyledTableHead>
          <TableBody>
            {batchData?.map(batch => (
              <BodyRow key={batch.id}>
                <BodyCell>
                  <Link
                    to={`lotes/${batch.batchId}`}
                    style={{ color: "#89BD23", fontWeight: 700 }}
                  >
                    {batch.batchId}
                  </Link>
                </BodyCell>
                <BodyCell>{batch.productName}</BodyCell>
                <BodyCell>{batch.seller}</BodyCell>
                <BodyCell>{batch.fomattedQuantity}</BodyCell>
                <BodyCell>{batch.reason}</BodyCell>
                <BodyCell>{batch.devolutionMethod}</BodyCell>
                <BodyCell>{batch.deadlineForAnalysis}</BodyCell>

                <BodyCell>
                  {batch.statusId === 7 && (
                    <ConfirmButton
                      disabled={batch.statusId !== 7}
                      onClick={() => handleOpenConfirm(batch.id)}
                    >
                      Devolver
                    </ConfirmButton>
                  )}
                </BodyCell>
              </BodyRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContent>
    </Box>
  );
};
