/* eslint-disable react/display-name */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import { forwardRef } from "react";
import { IoIosInformationCircle } from "react-icons/io";
import { TableVirtuoso } from "react-virtuoso";

import { BodyCell, HeadCell, StyledTableHead, TableContent } from "../../style";

const TableComponents = {
  Scroller: forwardRef((props, ref) => (
    <TableContent component={Paper} {...props} ref={ref} />
  )),
  Table: props => (
    <Table stickyHeader {...props} style={{ borderCollapse: "separate" }} />
  ),
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />)
};

export const SummaryStockTable = ({ stockData, search }) => {
  const TableHeader = () => (
    <StyledTableHead>
      <HeadCell width={"10rem"}>SKU</HeadCell>
      <HeadCell width={"25rem"}>Nome</HeadCell>
      <HeadCell width={"15rem"} align="center">
        ID Produto base
      </HeadCell>
      <HeadCell width={"10rem"} align="center">
        Margem
      </HeadCell>
      <HeadCell align="center">Estoque</HeadCell>
      <HeadCell align="center">Tipo</HeadCell>
    </StyledTableHead>
  );

  const filteredData = !search
    ? stockData
    : (stockData || [])?.filter(
        stock =>
          stock.sku_name?.toLowerCase().includes(search.toLowerCase().trim()) ||
          stock.sku?.includes(search.trim())
      );

  return (
    <TableVirtuoso
      style={{ height: 500 }}
      data={filteredData}
      components={{
        ...TableComponents,
        TableHead: TableHeader
      }}
      fixedHeaderContent={() => <TableHeader />}
      itemContent={(_, stock) => (
        <>
          <BodyCell>{stock.sku}</BodyCell>
          <BodyCell>{stock.name}</BodyCell>
          <BodyCell align="center">{stock.product_base_id}</BodyCell>
          <BodyCell align="center">{stock.margin}</BodyCell>

          <BodyCell align="center">
            <Box
              display="flex"
              style={{ gap: "0.5rem" }}
              justifyContent="center"
              alignItems="flex-end"
            >
              {stock.quantity_available}{" "}
              <Tooltip
                title={
                  <Typography style={{ whiteSpace: "pre-line" }}>
                    {stock.calculation_explanation.replace(/`/g, "")}
                  </Typography>
                }
                placement="top"
                arrow
              >
                <div style={{ height: "18px" }}>
                  <IoIosInformationCircle size={20} color="#b0b0b0" />
                </div>
              </Tooltip>
            </Box>
          </BodyCell>

          <BodyCell align="center" style={{ textTransform: "capitalize" }}>
            {stock.portion_type}
          </BodyCell>
        </>
      )}
    />
  );
};
