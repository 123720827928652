import { Typography, Box } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { InfoCard, AutoCompleteProductBase } from "shared";

import { Button } from "../style";

export const ProductView = ({
  productBase,
  setProductBase,
  onNext,
  selection
}) => (
  <>
    <InfoCard
      data={{
        Produto: { text: selection.batch?.product?.name, useStrong: true },
        Lote: { text: String(selection.batch?.id) }
      }}
    />
    <Typography
      style={{
        color: "#757575",
        fontFamily: "Inter",
        margin: "1.5rem 0 1rem"
      }}
    >
      Para qual produto deseja mover esse lote?
    </Typography>
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      justifyContent="space-between"
    >
      <AutoCompleteProductBase
        name="toProductBaseId"
        label="Produto de destino"
        value={productBase}
        onChange={(_, value) => setProductBase(value)}
        onlyPNC={{
          state: selection.type === "ruim",
          productName: selection.batch?.product?.name
        }}
      />
      <Box display="flex" justifyContent="flex-end" marginTop="1rem">
        <Button
          style={{ width: "48%" }}
          variant="contained"
          endIcon={<NavigateNextIcon fontSize="large" />}
          onClick={onNext}
          disabled={!productBase}
        >
          Avançar
        </Button>
      </Box>
    </Box>
  </>
);
