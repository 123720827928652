import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ContainerPage, TitlePage } from "components";

import { DialogUpload } from "./DialogUpload";
import { useStyles } from "./styles";

export default function UpdatePrePicking() {
  const classes = useStyles();

  return (
    <ContainerPage>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <TitlePage>Atualizar pré-pickings (atribuir separadores)</TitlePage>
      </Box>

      <Box display="flex" justifyContent="center" width="100%">
        <Alert severity="info" className={classes.acceptedColumnsAlert}>
          O arquivo deve conter as seguintes colunas na mesma ordem: id, hora de
          início, hora de conclusão, e-mail, nome, nome1, item, status,
          quantidade, descarte (quantidade), lote, feirinha, etiqueta inicial e
          etiqueta final.
        </Alert>
      </Box>

      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <DialogUpload />
      </Box>
    </ContainerPage>
  );
}
