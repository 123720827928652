import {
  Card,
  Typography,
  Box,
  Accordion,
  AccordionDetails
} from "@material-ui/core";
import { BsFlagFill } from "react-icons/bs";
import { AccordionSummary } from "shared";
import { formatDate, splitDate } from "utils";
import { v4 } from "uuid";

import { useStyles, LinearProgress } from "../styles";

export const CardComponent = ({
  batchData,
  status,
  count,
  title,
  color,
  handleSelect
}) => {
  const classes = useStyles();

  const content = {
    true: Object.entries(batchData)
      .sort(([sellerIdA, batchsA], [sellerIdB, batchsB]) => {
        return (
          splitDate(batchsA[0]?.deadlineFormat) -
          splitDate(batchsB[0]?.deadlineFormat)
        );
      })
      .map(([sellerId, batchs]) => {
        const isToday = batchs.some(({ isToday }) => isToday);
        const passedTheDeadline = batchs.some(
          ({ passedTheDeadline }) => passedTheDeadline
        );
        return (
          <Accordion key={sellerId} style={{ margin: 0 }}>
            <AccordionSummary>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography>
                  <span style={{ color: "#353535" }}>
                    {batchs[0]?.sellerId}
                  </span>
                  {" - " + batchs[0]?.sellerName}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ gap: "0.5rem" }}
                >
                  {isToday && passedTheDeadline ? (
                    <>
                      <BsFlagFill color="#D84315" size={12} />
                      <BsFlagFill color="#DAAA00" size={12} />
                    </>
                  ) : passedTheDeadline ? (
                    <BsFlagFill color="#D84315" size={12} />
                  ) : isToday ? (
                    <BsFlagFill color="#DAAA00" size={12} />
                  ) : null}
                  <Typography style={{ color: "#A3A3A3" }}>
                    {batchs.length}
                  </Typography>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0, flexDirection: "column" }}>
              {batchs
                .sort((a, b) => {
                  if (a.deadlineFormat === "Invalid Date") return 1;
                  if (b.deadlineFormat === "Invalid Date") return -1;
                  return (
                    splitDate(a.deadlineFormat) - splitDate(b.deadlineFormat)
                  );
                })
                .map(batch => {
                  const id = v4();
                  const isValidDate = batch.deadlineFormat !== "Invalid Date";

                  return (
                    <Box
                      onClick={() => handleSelect(batch)}
                      style={{ cursor: "pointer" }}
                      key={id}
                    >
                      <Box className={classes.mainBox}>
                        {isValidDate && (
                          <Box
                            position="absolute"
                            right="10px"
                            style={{
                              display: "flex",
                              flexDirection: "column"
                            }}
                          >
                            <span style={{ color: "#EB001B", fontWeight: 500 }}>
                              {batch.deadlineFormat}
                            </span>
                            <span
                              style={{
                                color: "#EB001B",
                                fontSize: "0.75rem",
                                fontWeight: 500
                              }}
                            >
                              {batch.deadlineFormatHour}
                            </span>
                          </Box>
                        )}

                        <Typography className={classes.titleBatch}>
                          Lote:{" "}
                          <span className={classes.span}>{batch.batchId}</span>
                        </Typography>
                        {batch.isMigrate && (
                          <Typography className={classes.migrateName}>
                            Migrado em:{" "}
                            <span className={classes.dateMigrate}>
                              {formatDate(batch.created)}
                            </span>
                          </Typography>
                        )}
                        <Typography className={classes.productName}>
                          {batch.productName}
                        </Typography>
                        <Typography className={classes.dateText}>
                          {batch?.combinedReturn}
                        </Typography>
                        <Typography className={classes.dateText}>
                          <strong>Quantidade pendente:</strong>{" "}
                          {batch?.unClassifiedQty}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </AccordionDetails>
          </Accordion>
        );
      }),
    false: status !== "pending" && (
      <Typography className={classes.warning}>Sem dados</Typography>
    )
  }[!!count];

  return (
    <Card className={classes.myCard}>
      <Box className={classes.scrollContainer}>
        <Box className={classes.line} color={color}></Box>
        <Box className={classes.boxHeader}>
          <Typography className={classes.title} style={{ color }}>
            {title}
          </Typography>
          <Typography className={classes.length}>{count}</Typography>
          {status === "pending" && <LinearProgress background={color} />}
        </Box>
        {content}
      </Box>
    </Card>
  );
};
