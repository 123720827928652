import * as Yup from "yup";

export const validationSchema = selection => {
  const { batch, available_quantity } = selection;

  return Yup.object().shape({
    quantity: Yup.number()
      .positive("Deve ser um número positivo")
      .typeError("Deve ser um número")
      .required("Campo obrigatório")
      .test(
        "integerValidation",
        "Deve ser um número inteiro",
        function (value) {
          return !(
            batch.product?.purchase_type === 2 && !Number.isInteger(value)
          );
        }
      )
      .max(available_quantity, `O valor máximo é ${available_quantity}`),
    storageReasonId: Yup.number().when("quantity", {
      is: 0,
      then: Yup.number().optional(),
      otherwise: Yup.number()
        .positive()
        .typeError("Deve ser um número")
        .required("Campo obrigatório")
    }),
    ...(selection.type === "diverso"
      ? {
          batchReasonId: Yup.number().when("quantity", {
            is: 0,
            then: Yup.number().optional(),
            otherwise: Yup.number()
              .positive()
              .typeError("Deve ser um número")
              .required("Campo obrigatório")
          })
        }
      : {})
  });
};
